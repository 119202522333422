import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Logo = styled.img`
    min-width: 250px;
    max-width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    margin-bottom: 70px;

    @media only screen and (max-width: 750px) { 
        min-width: 150px;
        max-width: 200px;

        margin-top: 50px;
        margin-bottom: 50px;
    }
`

const MainLogo = ({ siteTitle }) => (
    <Link to="/">
        <Logo
            src="/HealthyFox.svg" alt="HealthyFox Logo" />
    </Link>
)

export default MainLogo
