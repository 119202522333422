import React from "react"
import styled from "styled-components"
import theme from "./theme"

const Title = styled.h2`
    font-family: ${theme.fontSerif};
    font-weight: 30;

    font-size: 1.3em;
    color: ${theme.primaryFontColor};
    text-decoration: none; 
    text-align: center;

    width: 100%;

    border-bottom: 1px solid ${theme.primaryFontColor}; 
    line-height: 0.1em;

    margin-top: 100px;
    margin-bottom: 50px;

    @media only screen and (max-width: 750px) { 
        margin-top: 70px;
    }
`

const TitleBackground = styled.span `
    background: ${theme.lightColor};
    padding: 0 10px; 
`

const TitleHeader = ({ title }) => (
    <Title>
        <TitleBackground>
            { title }
        </TitleBackground>
    </Title>
)

export default TitleHeader