// bug 1: fix because scss variable returns a string of a string
// https://github.com/css-modules/css-modules/issues/86

// bug 2: variables work with gatsby develop but not with gatsby build -> currently all values are duplicated
// see variables.scss
    
const theme = {
    lightColor: "#FFFFFF",
    accentColor: "#EFF2EB",

    primaryFontColor: "#464646",
    lightFontColor: "#A7A7A7",

    linkFontColor: "#36610E",
    linkHoverColor: "#AE4C02",
    lightLinkColor: "#CD8E6A",

    fontSansSerif: 'Abel, sans-serif',
    fontSerif: 'Roboto Slab, serif',

    // fontSansSerif: variables.fontSansSerif.split("'").join("").split('"').join(""),
    // fontSerif: variables.fontSerif.split("'").join("").split('"').join("")
};

export default theme;
