import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import theme from "./theme"

const MainMenuContainer = styled.div`
    background-color: ${theme.accentColor};
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-family: ${theme.fontSerif};
    font-weight: 30; 
    font-size: 1.2em;
    min-width: 350px;

    @media only screen and (max-width: 750px) { 
        font-size: 1em;
    }
`

const MainMenuItem = styled.div`
    display: inline-flex;
    padding: 0px 20px 0px 20px;
    color: ${theme.accentColor};
    
    
    a:link, a:visited {
        text-decoration: none;
        color: #2D4D53;
    }

    a:hover {
        color: #AE4C02;
    }
`

const MainMenu = ({ siteTitle }) => (
    <MainMenuContainer id="main-menu">
        <MainMenuItem>
            <Link to="/">
                HOME
            </Link>
        </MainMenuItem>
        <MainMenuItem>
            <Link to="/recipes">
                REZEPTE
            </Link>
        </MainMenuItem>
        <MainMenuItem>
            <Link to="/blog">
                BLOG
            </Link>
        </MainMenuItem>
        <MainMenuItem>
            <Link to="/sites/AboutMe">
                ÜBER MICH
            </Link>
        </MainMenuItem>
    </MainMenuContainer>
    
)

export default MainMenu