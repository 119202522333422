/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"

import theme from "./theme"
import MainLogo from "./mainLogo"
import MainMenu from "./mainMenu"
import Footer from "./footer"
import "./layout.scss"

const Body = styled.div`
  background-color: ${theme.lightColor};
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <Helmet
          link={[
                {
                  "rel": "apple-touch-icon", 
                  "sizes": "180x180",
                  "href": "/apple-touch-icon.png"
                },
                {
                  "rel": "icon", 
                  "type": "image/png",
                  "sizes": "32x32",
                  "href": "/favicon-32x32.png"
                },
                {
                  "rel": "icon", 
                  "type": "image/png",
                  "sizes": "16x16",
                  "href": "/favicon-16x16.png"
                },
                {
                  "rel": "manifest", 
                  "href": "/site.webmanifest"
                },
              ]}
        />

        <Body>
          <MainMenu/>
          <MainLogo/>

          {children}
          
          <Footer/>
        </Body>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

