import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const StyledImg = styled(Img)`
    min-width: 280px;
`;

const BlogPostPreviewImage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.imageName);
      });
      if (!image) { 
        return null; 
      } 
      
      return (
        <StyledImg
          fluid={image.node.childImageSharp.fluid} />
      );
    }}
  />
)
export default BlogPostPreviewImage

