import React from "react"
import styled from "styled-components"
import theme from "./layout/theme"

const StyledBadge = styled.div`
    font-family: ${theme.fontSansSerif};
    color: ${theme.lightFontColor};
    text-transform: uppercase;
`

const Badge = ({ title }) => (
    
    <StyledBadge >
        { title }
    </StyledBadge>
)

export default Badge