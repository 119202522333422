import React from "react"
import styled from "styled-components"
import theme from "./theme"
import { Link } from "gatsby"

const FooterContainer = styled.div`
    margin-top: 20px;
    padding: 10px;
    min-width: 350px;

    background-color: ${theme.lightColor};
    font-family: ${theme.fontSerif};
    color: ${theme.primaryFontColor};
    text-align: center;

    a:link, a:visited {
      text-decoration: none;
      color: ${theme.primaryFontColor};
    }
    
    a:hover {
        color: ${theme.primaryFontColor};
    }
`

const Copyright = styled.div`
    padding: 10px;
    font-size: 0.8em;
`

const Footer = () => (
  <FooterContainer>
    <footer>
      <Link to="/sites/Imprint">Impressum</Link> &nbsp; -  &nbsp;
      <Link to="/sites/PrivacyPolicy">Datenschutzerklärung</Link> &nbsp; -  &nbsp;
      <Link to="/sites/Monetization">* Monetarisierung</Link>
      <Copyright>© 2020 Linda Werner</Copyright>
    </footer>
  </FooterContainer>
)


export default Footer
