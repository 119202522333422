import styled from "styled-components"

import theme from "./theme"

export const ContentContainer = styled.div`
    padding-left: 7%;
    padding-right: 7%;

    margin: auto;
    min-width: 350px;

    @media (min-width: 1500px) {
    width: 1500px;
    }
`

export const Title = styled.h3`
    font-family: ${theme.fontSansSerif};
    font-size: 1.7em;
    font-weight: 100;
    text-align: center;
    color: ${theme.primaryFontColor};
    text-decoration: none; 
    
    margin: 0px;
`

export const MainImageContainer = styled.div`
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
`

export const DateLine = styled.p`
  font-family: ${theme.fontSansSerif};
  font-weight: 100;
  font-size: 1em;
  color: ${theme.lightFontColor};
  text-align: center;

  padding-top: 8px;
  margin: 0px;
`

export const BadgeWrapper = styled.div`
  text-align: center;
  font-size: 0.8em;

  padding-top: 15px;
  padding-bottom: 15px;
`

export const ImageContainer = styled.div`
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
`