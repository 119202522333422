import React from "react"
import styled from "styled-components"
import Badge from "./Badge"


const BadgeWrapper = styled.div`
    display: inline-flex;
    padding: 5px 0px 5px 0px;
`

const TagSpacer = styled.div`
    width: 5px;
    min-width: 5px;
`

const Badges = ({ categoryName, tagNames }) => {
    const tags = []
    
    var allTagNames = []
    if (categoryName) allTagNames.unshift(categoryName)
    if (tagNames) {
        for (var i = 0; i < tagNames.length; i++) { 
            allTagNames.push(tagNames[i])
        }
    }

    for (var i = 0; i < allTagNames.length; i++) { 
        var tagName = allTagNames[i]
        if (i < allTagNames.length-1) {
            tagName = tagName + ", "
        }
        tags.push(<Badge 
            title={tagName} 
            fSize="0.2em"/>)
        tags.push(<TagSpacer/>)
    }
  
    return (
        <BadgeWrapper>
            {tags}
        </BadgeWrapper>
    )
}

export default Badges